/** @jsx jsx */
import {rgba} from 'polished';
import {jsx, Box, Container, Flex, Input, Button, Textarea} from 'theme-ui';

import SectionHeading from 'components/section-heading';
import {Phone, Email, LocationOn, Map, Facebook, Instagram} from '@material-ui/icons';
import {Link} from "gatsby"
import {encode} from "querystring";
import {useState} from "react";

const Contact = () => {
    const [state, setState] = useState({})

    const handleChange = (e) => {
        setState({...state, [e.target.name]: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('https://be.yinyang.vn/admin/api/messages/send', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(state),
        }).then(() => {
            alert('Đã gửi.')
            // navigate(form.getAttribute('action'))
        })
            .catch((error) => alert(error))
    }

    return (
        <Box id="contact" as="section" sx={styles.section}>
            <Container>
                <Box sx={styles.contentWrapper}>
                    <Box sx={styles.leftContent}>
                        <SectionHeading
                            sx={styles.heading}
                            title="Mọi thắc mắc xin liên hệ"
                            description=""
                        />
                        <Box as="form" sx={styles.form} onSubmit={handleSubmit}>
                            <Box as="label" htmlFor="email" variant="styles.srOnly">
                                Email
                            </Box>
                            <Input name="email" type="email" id="email" placeholder="Email ..." onChange={handleChange}/>
                            <Box as="label" htmlFor="message" variant="styles.srOnly">
                                Message
                            </Box>
                            <Textarea id="content" rows={5} name="content"
                                      placeholder="Những thắc mắc bạn muốn giải đáp ..." onChange={handleChange}></Textarea>
                            <Button variant="white">Send</Button>
                        </Box>
                    </Box>
                    <Box sx={styles.rightContent}>
                        <Box sx={styles.informationLine}>
                            <LocationOn/>
                            <label>Phòng 1601 - Toà Mê Linh Point <br/> Số 2 Ngô Đức Kế, p. Bến Nghé, q.1, tp
                                HCM</label>
                        </Box>
                        <Box sx={styles.informationLine}>
                            <Email/>
                            <label>publishing@yinyang.vn</label>
                        </Box>
                        <Box sx={styles.informationIcons}>
                            <Link target="_blank" to={'https://www.facebook.com/YYMediaCo'}>
                                <Facebook fontSize="large"/>
                            </Link>
                            <Link target="_blank" to={'https://www.instagram.com/yinyang.media'}>
                                <Instagram fontSize="large"/>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default Contact;

const styles = {
    section: {
        backgroundColor: '#f9fafc',
        pt: [30, null, null, null, 60],
        pb: [30, null, null, 80, 100],
    },
    contentWrapper: {
        gap: [20, 20, 20, 30, 50],
        display: ['flex', null, null, null, 'grid'],
        alignItems: 'center',
        flexDirection: ['column', null, null, null, null],
        gridTemplateColumns: ['unset', null, null, null, 'repeat(2,1fr)'],
        px: [null, null, null, null, 20, 50]

    },
    leftContent: {
        m: [0, '30px 0px 0', '30px 0px 0', 0],
        textAlign: 'center',
        width: ['80%', '80%', '80%', '60%', '100%', '100%']
    },
    heading: {
        color: 'black',
        mb: [30, 30, 50],
        h2: {
            fontSize: [22, 28, '36px'],
            lineHeight: 1.4,
            letterSpacing: 'heading',
        },
        p: {
            lineHeight: [2, 3.12],
            mt: [20, 0],
            letterSpacing: 'heading',
            color: rgba('#000', 0.6),
        },
    },
    content: {
        maxWidth: '555px',
        margin: '0 auto',
        textAlign: 'center',
    },
    form: {
        alignItems: 'left',
        display: ['block', 'block'],
        gap: 10,
        input: {
            // backgroundColor: rgba('#000', 0.08),
            borderRadius: '5px',
            borderColor: 'black',
            color: rgba('#000', 0.8),
            flexGrow: 1,
            fontFamily: 'body',
            height: 'auto',
            px: '30px',
            py: '0',
            minHeight: [50, 50, 60],
            width: ['100%', 'auto'],
            '::placeholder': {
                color: rgba('#000', 0.8),
            },
            my: '10px'
        },
        button: {
            backgroundColor: '#000',
            color: 'white',
            minHeight: [50, 50, 60],
            fontSize: [14, 16],
            padding: '0 30px',
            whiteSpace: 'nowrap',
            width: ['100%', "auto"],
            // ml: [0, 3],
            // mt: [4, 0],
            ':hover': {
                backgroundColor: '#999',
                color: '#020718',
            },
            display: 'block',
            my: '10px'
        },
        textarea: {
            color: rgba('#000', 0.8),
            fontFamily: 'body',
            borderColor: 'black',
            height: ['150px'],
            '::placeholder': {
                color: rgba('#000', 0.8),
            },
            resize: 'none',
        }
    },
    featureWrapper: {
        gap: ['40px 20px', null, null, null, '30px'],
        display: 'grid',
        gridTemplateColumns: [
            'repeat(2,1fr)',
            null,
            null,
            'repeat(3,180px)',
            'repeat(3,1fr)',
        ],
        justifyContent: ['unset', null, null, 'center', 'flex-start'],
    },
    rightContent: {
        position: 'relative',
        mt: [6, 6, 6, 6, 0],
        maxWidth: '100%',
        width: ['80%', '80%', '80%', '60%', '100%', '100%']

    },
    informationLine: {
        display: 'flex',
        color: '#000',
        mx: 20,
        gap: 10,
        mb: 2,
        a: {
            color: '#000'
        }

    },

    informationIcons: {
        display: 'flex',
        color: '#000',
        mx: 17,
        gap: 10,
        mb: 2,
        a: {
            color: '#000'
        }
    }

};
